var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};

// src/schema.ts
import { z } from "zod";
var MetadataInput = z.object({
  type: z.enum(["text", "date", "color", "email", "url", "hidden"], {
    description: "The type field maps to a certain subset of HTML <input> types, which influences how the form renders. "
  }),
  name: z.string({
    description: "Name of the attribute to collect."
  }).regex(
    /^[A-Za-z-]+$/,
    "Only letters and dashes are allowed in the name. No spaces, special characters, or separators."
  ),
  label: z.string({
    description: "Label displayed to users. Defaults to the name field."
  }).optional(),
  required: z.boolean({
    description: "Check if you require users to enter this before they complete the purchase."
  }).optional().default(false),
  placeholder: z.string({
    description: "Placeholder displayed to users."
  }).optional(),
  value: z.string({
    description: "Value to use for hidden metadata inputs."
  }).optional(),
  defaultValue: z.string({
    description: "Default value for the attribute."
  }).optional(),
  public: z.boolean({
    description: "If you check this, the attribute will be visible to everyone. Recommended: leave unchecked."
  }).optional()
});
var PaywallLockConfig = z.object({
  order: z.number().int().optional(),
  name: z.string({
    description: "Name of the lock to display."
  }).optional(),
  network: z.number().int().positive().optional(),
  metadataInputs: z.array(MetadataInput).optional(),
  referrer: z.string({
    description: "(Recommended) The address of the purchase referrer. This address may receive a referrer fee if the lock was configured for this, and will receive Unlock Governance tokens if applicable. Put your address if unsure."
  }).optional(),
  recurringPayments: z.union([z.string(), z.number()], {
    description: "The number of time a membership should be renewed automatically. This only applies to ERC20 locks."
  }).optional(),
  captcha: z.boolean({
    description: "If enabled, the users will be prompted to go through a captcha during the checkout process. Warning: This only works if the lock is configured with a purchase hook that verifies that captcha is valid."
  }).optional(),
  password: z.boolean({
    description: "If enabled, the user will be prompted to enter a password in order to complete their purchases. Warning: This only works if the lock is connected to a hook that will handle the password verification."
  }).optional(),
  promo: z.union([
    z.boolean({
      description: 'If enabled, the user will be prompted to enter an optional promo code in order to receive discounts. Warning: This only works if the lock is connected to a hook that will handle the promo codes. This cannot be used at the same time as the "Password Required" option above'
    }),
    z.string({
      description: 'If set, the user optional promo code field will be pre-filled with this value. Warning: This only works if the lock is connected to a hook that will handle the promo codes. This cannot be used at the same time as the "Password Required" option above'
    })
  ]).optional(),
  emailRequired: z.boolean({
    description: "If enabled, the user will be prompted to enter an email which will be stored as metadata and be visible to any lock manager on the dashboard. Additionaly a confirmation email will be sent to the user once the NFT membership has been minted."
  }).optional(),
  minRecipients: z.coerce.number({
    description: "During checkout, users can buy multiple memberships at once. You can set a minimum number they can buy."
  }).int().default(1).nullable().optional(),
  maxRecipients: z.coerce.number({
    description: `(Optional) Set the max number of memberships a user can purchase. Note: By default, checkout doesn't allow fiddling with quantity. You have to set maxRecipients to allow for changing to quantity.`
  }).int().default(1).nullable().optional(),
  default: z.boolean().optional(),
  dataBuilder: z.string({
    description: "(Optional) If set to a url, checkout will call the URL through a proxy with recipient, lockAddress, and network field for a json response containing data string field. This will be passed to the purchase function when user is claiming or buying the key as is. Make sure the returned data is valid bytes."
  }).optional(),
  skipRecipient: z.boolean({
    description: "When set to true, the checkout flow will not let the user customize the recipient of the NFT membership."
  }).default(true).optional(),
  recipient: z.string({
    description: "Hardcoded address for the recipient of the NFT. Can be used with skipRecipient."
  }).optional(),
  paymentMethods: z.object({
    card: z.boolean().optional(),
    crypto: z.boolean().optional(),
    crossmint: z.boolean().optional(),
    swap: z.boolean().optional(),
    crosschain: z.boolean().optional(),
    claim: z.boolean().optional()
  }).optional()
});
var PaywallConfig = z.object({
  title: z.string({
    description: "Title for your checkout. This will show up on the head."
  }).optional(),
  icon: z.string({
    description: "The URL for a icon to display in the top left corner of the modal."
  }).optional(),
  locks: z.record(PaywallLockConfig),
  metadataInputs: z.array(MetadataInput).optional(),
  persistentCheckout: z.boolean({
    description: "If checked, the checkout modal cannot be closed. This is especially useful when the checkout UI is embedded directly. Leave unchecked if unsure."
  }).optional(),
  redirectUri: z.string({
    description: "The address of a webpage where the user will be redirected when they complete the checkout flow."
  }).url().optional(),
  useDelegatedProvider: z.boolean().optional(),
  network: z.number().int().optional(),
  referrer: z.string({
    description: "(Recommended) The address of the purchase referrer. This address may receive a referrer fee if the lock was configured for this, and will receive Unlock Governance tokens if applicable. Put your address if unsure."
  }).optional(),
  messageToSign: z.string({
    description: "(Optional) If supplied, the user is prompted to sign this message using their wallet. Your application needs to handle the signature to identify the user."
  }).optional(),
  endingCallToAction: z.string({
    description: "Show a custom text on the final button that triggers a redirect"
  }).optional(),
  pessimistic: z.boolean({
    description: "By default, to reduce friction, we do not require users to wait for the transaction to be mined before offering them to be redirected. If you check this, users will need to wait for the transaction to have been mined in order to proceed to the next step."
  }).default(true).optional(),
  captcha: z.boolean({
    description: "If set true, the users will be prompted to go through a captcha during the checkout process. This is better used in conjunction with a purchase hook that verifies that captcha is valid."
  }).optional(),
  minRecipients: z.coerce.number({
    description: "Set the minimum number of memberships a user needs to purchase."
  }).int().nullable().optional(),
  maxRecipients: z.coerce.number({
    description: `(Optional) Set the max number of memberships a user can purchase. Note: By default, checkout doesn't allow fiddling with quantity. You have to set maxRecipients to allow for changing to quantity.`
  }).int().positive().nullable().optional(),
  hideSoldOut: z.boolean({
    description: "When enabled, sold out locks are not shown to users when they load the checkout modal."
  }).optional(),
  password: z.boolean({
    description: "If enabled, the user will be prompted to enter a password in order to complete their purchases. Warning: This only works if the lock is connected to a hook that will handle the password verification."
  }).optional(),
  promo: z.union([
    z.boolean({
      description: 'If enabled, the user will be prompted to enter an optional promo code in order to receive discounts. Warning: This only works if the lock is connected to a hook that will handle the promo codes. This cannot be used at the same time as the "Password Required" option above'
    }),
    z.string({
      description: 'If set, the user optional promo code field will be pre-filled with this value. Warning: This only works if the lock is connected to a hook that will handle the promo codes. This cannot be used at the same time as the "Password Required" option above'
    })
  ]).optional(),
  emailRequired: z.boolean({
    description: "If enabled, the user will be prompted to enter an email which will be stored as metadata and be visible to any lock manager on the dashboard. Additionaly a confirmation email will be sent to the user once the NFT membership has been minted."
  }).optional(),
  dataBuilder: z.string({
    description: "(Optional) If set to a url, checkout will call the URL through a proxy with recipient, lockAddress, and network field for a json response containing data string field. This will be passed to the purchase function when user is claiming or buying the key as is. Make sure the returned data is valid bytes."
  }).optional(),
  recurringPayments: z.union([z.string(), z.number()], {
    description: "The number of time a membership should be renewed automatically. This only applies to ERC20 locks."
  }).optional(),
  skipRecipient: z.boolean({
    description: "When set to true, the checkout flow will not let the user customize the recipient of the NFT membership."
  }).default(true).optional(),
  skipSelect: z.boolean({
    description: "When set to true and there is only one lock, the checkout flow will skip the lock selection step."
  }).default(false).optional(),
  expectedAddress: z.string({
    description: "Expected wallet address for user."
  }).optional(),
  autoconnect: z.boolean({
    description: "(Advanced): forces the use the provider from the parent window when the checkout is embeded as an iframe."
  }).default(false).optional(),
  recipient: z.string({
    description: "Hardcoded address for the recipient of the NFT. Can be used with skipRecipient."
  }).optional(),
  paymentMethods: z.object({
    card: z.boolean().optional(),
    crypto: z.boolean().optional(),
    crossmint: z.boolean().optional(),
    swap: z.boolean().optional(),
    crosschain: z.boolean().optional(),
    claim: z.boolean().optional()
  }).optional()
}).passthrough();
var PaywallLocksConfig = z.record(z.string(), PaywallLockConfig);
var AttendeeRefund = z.object({
  amount: z.number().positive(),
  currency: z.string().optional().nullable(),
  // undefined/null for native currency
  network: z.number().int().positive()
});
var EventObject = z.object({
  name: z.string(),
  slug: z.string(),
  image: z.string().url(),
  layout: z.string(),
  description: z.string(),
  requiresApproval: z.boolean(),
  hideRemaining: z.boolean().optional(),
  // This should rather be a checkoutConfig property.
  attendeeRefund: AttendeeRefund.optional(),
  emailSender: z.string(),
  replyTo: z.string(),
  ticket: z.object({
    event_cover_image: z.string(),
    event_start_date: z.string(),
    event_start_time: z.string(),
    event_end_date: z.string(),
    event_end_time: z.string(),
    event_timezone: z.string(),
    event_address: z.string(),
    event_url: z.string()
  })
});

// src/constants.ts
var PAGE_SIZE = 30;
var UNLOCK_CONSOLE_MESSAGE = `
*********************************************************************
Thanks for checking out Unlock!

We're building the missing payments layer for the web: a protocol
which enables creators to monetize their content with a few lines of
code in a fully decentralized way.

We would love your help.

Jobs: https://unlock-protocol.com/jobs

Open source community: https://github.com/unlock-protocol/unlock

Good first issues: https://github.com/unlock-protocol/unlock/issues?q=is%3Aissue+is%3Aopen+label%3A%22good+first+issue%22

Get in touch: hello@unlock-protocol.com

Love,

The Unlock team
*********************************************************************
`;

// src/utils/index.ts
import { z as z2 } from "zod";
var LockTypeSchema = z2.object({
  isEvent: z2.boolean().default(false),
  isCertification: z2.boolean().default(false),
  isStamp: z2.boolean().default(false)
});
var getLockTypeByMetadata = (metadata) => {
  const attributes = (metadata == null ? void 0 : metadata.attributes) || [];
  const hasAttribute = (name) => {
    return attributes.some(
      (attribute) => {
        var _a;
        return (_a = attribute == null ? void 0 : attribute.trait_type) == null ? void 0 : _a.startsWith(name);
      }
    );
  };
  return {
    isEvent: hasAttribute("event"),
    isCertification: hasAttribute("certification"),
    isStamp: hasAttribute("stamp")
  };
};

// src/utils/metadata.ts
function toFormData({
  name,
  slug,
  description,
  attributes,
  animation_url,
  external_url,
  youtube_url,
  background_color,
  image
}) {
  const categorizedAttrs = categorizeAttributes(attributes || []);
  const metadata = __spreadValues({}, categorizedAttrs);
  if (slug) {
    metadata.slug = slug;
  }
  if (name) {
    metadata.name = name;
  }
  if (description) {
    metadata.description = description;
  }
  if (animation_url) {
    metadata.animation_url = animation_url;
  }
  if (external_url) {
    metadata.external_url = external_url;
  }
  if (youtube_url) {
    metadata.youtube_url = youtube_url;
  }
  if (image) {
    metadata.image = image;
  }
  if (background_color) {
    metadata.background_color = background_color.startsWith("#") ? `#${background_color}` : background_color;
  }
  return metadata;
}
var categorizeAttributes = (attributes = []) => {
  if (!attributes) {
    return {};
  }
  const ticket = attributes.reduce((item, { trait_type, value }) => {
    item[trait_type.toLowerCase()] = value;
    return item;
  }, {});
  const certification = attributes.reduce((item, { trait_type, value }) => {
    item[trait_type.toLowerCase()] = value;
    return item;
  }, {});
  const stats = attributes.filter(
    (item) => item.display_type === "number" && typeof item.value === "number"
  );
  const levels = attributes.filter(
    (item) => typeof item.value === "number" && !item.display_type
  );
  const properties = attributes.filter(
    (item) => typeof item.value === "string" && !item.max_value && !item.trait_type.startsWith("event_") && !item.trait_type.startsWith("certification_")
  );
  return {
    ticket,
    certification,
    levels,
    properties,
    stats
  };
};
var formDataToMetadata = ({
  name,
  slug,
  layout,
  description,
  animation_url,
  youtube_url,
  external_url,
  background_color,
  ticket,
  properties,
  levels,
  stats,
  image,
  certification
}) => {
  var _a;
  const metadata = {
    name,
    image,
    attributes: []
  };
  if (certification == null ? void 0 : certification.certification_issuer) {
    metadata.attributes.push({
      trait_type: "certification_issuer",
      value: certification.certification_issuer
    });
  }
  if (ticket == null ? void 0 : ticket.event_cover_image) {
    metadata.attributes.push({
      trait_type: "event_cover_image",
      value: ticket.event_cover_image
    });
  }
  if (ticket == null ? void 0 : ticket.event_start_date) {
    metadata.attributes.push({
      trait_type: "event_start_date",
      value: ticket.event_start_date
    });
  }
  if (ticket == null ? void 0 : ticket.event_start_time) {
    metadata.attributes.push({
      trait_type: "event_start_time",
      value: ticket.event_start_time
    });
  }
  if (ticket == null ? void 0 : ticket.event_end_date) {
    metadata.attributes.push({
      trait_type: "event_end_date",
      value: ticket.event_end_date
    });
  }
  if (ticket == null ? void 0 : ticket.event_end_time) {
    metadata.attributes.push({
      trait_type: "event_end_time",
      value: ticket.event_end_time
    });
  }
  if (ticket == null ? void 0 : ticket.event_timezone) {
    metadata.attributes.push({
      trait_type: "event_timezone",
      value: ticket.event_timezone
    });
  }
  if (ticket == null ? void 0 : ticket.event_address) {
    metadata.attributes.push({
      trait_type: "event_address",
      value: ticket.event_address
    });
  }
  if (ticket == null ? void 0 : ticket.event_url) {
    metadata.attributes.push({
      trait_type: "event_url",
      value: ticket.event_url
    });
  }
  const propertyAttributes = properties == null ? void 0 : properties.filter(
    (item) => item.trait_type && item.value
  );
  const levelsAttributes = levels == null ? void 0 : levels.filter(
    (item) => item.trait_type && item.value && item.max_value
  );
  const statsAttributes = stats == null ? void 0 : stats.filter(
    (item) => item.trait_type && item.value && item.max_value
  );
  if (propertyAttributes == null ? void 0 : propertyAttributes.length) {
    metadata.attributes.push(...propertyAttributes);
  }
  if (levelsAttributes == null ? void 0 : levelsAttributes.length) {
    metadata.attributes.push(...levelsAttributes);
  }
  if (statsAttributes == null ? void 0 : statsAttributes.length) {
    metadata.attributes.push(...statsAttributes);
  }
  if (background_color && background_color.length === 7) {
    metadata.background_color = (_a = background_color == null ? void 0 : background_color.trim()) == null ? void 0 : _a.replace("#", "");
  }
  if (description) {
    metadata.description = description;
  }
  if (youtube_url) {
    metadata.youtube_url = youtube_url;
  }
  if (animation_url) {
    metadata.animation_url = animation_url;
  }
  if (external_url) {
    metadata.external_url = external_url;
  }
  if (layout) {
    metadata.layout = layout;
  }
  if (slug) {
    metadata.slug = slug;
  }
  return metadata;
};

// src/utils/currencies.ts
var Currencies = [
  { symbol: "$", currency: "usd" },
  { symbol: "\u20AC", currency: "eur" },
  { symbol: "R$", currency: "brl" }
];
export {
  AttendeeRefund,
  Currencies,
  EventObject,
  MetadataInput,
  PAGE_SIZE,
  PaywallConfig,
  PaywallLockConfig,
  PaywallLocksConfig,
  UNLOCK_CONSOLE_MESSAGE,
  categorizeAttributes,
  formDataToMetadata,
  getLockTypeByMetadata,
  toFormData
};
